import gql from "graphql-tag";

export const MarketingPromoContestPrizes = {
    Queries: {
        MarketingPromoContestPrizes: gql`
        query marketingPromoContestPrizes($marketingPromoContestId: ID) {
            marketingPromoContestPrizes(marketingPromoContestId: $marketingPromoContestId) {
                id
                HtmlHeadline
                HtmlSubline
                HtmlDescription
                ImageUrl
                Code
                DetailUrl
                IsDiscountOnlyPrize
                DrawPrizeAt
                Quantity
                Value
                WinnerNotifiedAt
                PrizeDrawnAt
                createdAt
                updatedAt
                deletedAt
                MarketingPromoContestId
                ItemId
                MarketingPromoParticipantId
                MarketingPromoParticipant {
                    Email
                }
            }
        }`,
        MarketingPromoContestPrize: gql`
        query marketingPromoContestPrize($id: ID) {
            marketingPromoContestPrize(id: $id) {
                id
                HtmlHeadline
                HtmlSubline
                HtmlDescription
                ImageUrl
                Code
                DetailUrl
                IsDiscountOnlyPrize
                DrawPrizeAt
                Quantity
                Value
                WinnerNotifiedAt
                PrizeDrawnAt
                createdAt
                updatedAt
                deletedAt
                MarketingPromoContestId
                ItemId
                MarketingPromoParticipantId
            }
        }`,
    },
    Mutations: {
        CreateMarketingPromoContestPrize: gql`
        mutation createMarketingPromoContestPrize($marketingPromoContestPrize: MarketingPromoContestPrizesInput) {
            createMarketingPromoContestPrize(marketingPromoContestPrize: $marketingPromoContestPrize) {
                IsSuccess
                Message
            }
        }`,
        UpdateMarketingPromoContestPrize: gql`
        mutation updateMarketingPromoContestPrize($marketingPromoContestPrize: MarketingPromoContestPrizesInput) {
            updateMarketingPromoContestPrize(marketingPromoContestPrize: $marketingPromoContestPrize) {
                IsSuccess
                Message
            }
        }`,
        DeleteMarketingPromoContestPrize: gql`
        mutation deleteMarketingPromoContestPrize($marketingPromoContestPrizeId: ID) {
            deleteMarketingPromoContestPrize(marketingPromoContestPrizeId: $marketingPromoContestPrizeId) {
                IsSuccess
                Message
            }
        }`,
        SendMarketingPromoContestPrizeWinnerEmail: gql`
        mutation sendMarketingPromoContestPrizeWinnerEmail($marketingPromoContestPrizeId: ID, $email: String) {
            sendMarketingPromoContestPrizeWinnerEmail(marketingPromoContestPrizeId: $marketingPromoContestPrizeId, email: $email) {
                IsSuccess
                Message
            }
        }`,
    },
};
