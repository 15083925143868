<template lang="pug">
#app
  Header(v-if="$session.exists()")
  .container-fluid
    RouterView.p-3(:key="$route.path")
</template>

<script>
import Header from "@/views/components/header/Header";

export default {
  name: 'App',
  components: {
    Header,
  }
}
</script>

<style lang="scss">
@import "assets/styles/base";
</style>
