<template lang="pug">
  .update-marketing-promo-contest-prize-modal.p-4
    .container-fluid
      .row(v-if="marketingPromoContestPrize")
        .col-12
          h5 Artikelpreis bearbeiten

        .col-12.mt-2(v-if="item")
          b {{ item.CustomAttributes.Name1 }}
          p {{ item.CustomAttributes.Custom_Product_Arrangement }}

        .col-12.mt-2
          .form-group
            label.form-label Item-Id*
            input.form-control(v-model="marketingPromoContestPrize.ItemId" @change="loadItem")

        .col-12
          .form-group
            label.form-label Headline*
            input.form-control(v-model="marketingPromoContestPrize.HtmlHeadline")

        .col-12.mt-2
          .form-group
            label.form-label Subline*
            input.form-control(v-model="marketingPromoContestPrize.HtmlSubline")

        .col-12.mt-2
          .form-group
            label.form-label Beschreibung*
            textarea-autosize.form-control(v-model="marketingPromoContestPrize.HtmlDescription")

        .col-12.mt-2
          .form-group
            label.form-label Bild-Url*
            input.form-control(v-model="marketingPromoContestPrize.ImageUrl")

        .col-12.mt-2
          .form-group
            label.form-label Preis ziehen am*
            input.form-control(type="datetime-local" :value="marketingPromoContestPrize.DrawPrizeAt | formatInputDateTime" @change="marketingPromoContestPrize.DrawPrizeAt = $event.target.value;")

        .col-6.mt-2
          button.button.button-primary.button-tdays.w-100(type='button' @click="updateMarketingPromoContestPrize")
            | speichern
        .col-6.mt-2
          button.button.button-primary.button-tpics.w-100(type='button' @click="$emit('close')")
            | schließen

</template>

<script>
import EventBus from "@/views/event-bus";
import {MarketingPromoContestPrizes} from "@/graphql/MarketingPromoContestPrizes.ts";
import {Items} from "@/graphql/Items.ts";

export default {
  name: "UpdateMarketingPromoContestModal",
  props: {
    marketingPromoContestPrizeId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    try {
      this.loadMarketingPromoContestPrize();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  data() {
    return {
      item: undefined,
      marketingPromoContestPrize: undefined,
    }
  },
  methods: {
    loadMarketingPromoContestPrize() {
      try {
        this.$apollo.query({
          query: MarketingPromoContestPrizes.Queries.MarketingPromoContestPrize,
          fetchPolicy: "no-cache",
          variables: {
            id: this.marketingPromoContestPrizeId,
          }
        })
        .then(({ data }) => {
          EventBus.$emit("changeLoadingState", false);
          if (!data || !data.marketingPromoContestPrize) {
            return this.$alert("Es konnte kein Preis geladen werden.");
          }
          this.marketingPromoContestPrize = data.marketingPromoContestPrize;
          this.loadItem();
        })
        .catch((e) => {
          EventBus.$emit("changeLoadingState", false);
          console.log(e);
          this.$alert(e.message);
        })
      } catch (e) {
        EventBus.$emit("changeLoadingState", false);
        console.log(e);
        this.$alert(e.message);
      }
    },
    updateMarketingPromoContestPrize() {
      try {
        if (!this.item) {
          return this.$alert("Bitte vergeben Sie eine gültige Artikel-Id.");
        }
        if (!this.marketingPromoContestPrize.ItemId) {
          return this.$alert("Bitte vergeben Sie eine Artikel-Id.");
        }
        if (!this.marketingPromoContestPrize.HtmlHeadline) {
          return this.$alert("Bitte vergeben Sie eine Headline.");
        }
        if (!this.marketingPromoContestPrize.HtmlSubline) {
          return this.$alert("Bitte vergeben Sie eine Subline.");
        }
        if (!this.marketingPromoContestPrize.HtmlDescription) {
          return this.$alert("Bitte vergeben Sie eine Beschreibung.");
        }
        if (!this.marketingPromoContestPrize.DrawPrizeAt) {
          return this.$alert("Bitte vergeben Sie einen Auslosezeitpunkt.");
        }

        EventBus.$emit("changeLoadingState", true);
        this.$apollo.mutate({
          mutation: MarketingPromoContestPrizes.Mutations.UpdateMarketingPromoContestPrize,
          variables: {
            marketingPromoContestPrize: this.marketingPromoContestPrize,
          }
        })
            .then(({data}) => {
              EventBus.$emit("changeLoadingState", false);
              if (!data || !data.updateMarketingPromoContestPrize) {
                return this.$alert("Es konnte kein Preis erstellt werden.");
              }
              if (data.updateMarketingPromoContestPrize.Message) {
                this.$alert(data.updateMarketingPromoContestPrize.Message);
              }
              if (data.updateMarketingPromoContestPrize.IsSuccess) {
                EventBus.$emit("updateMarketingPromoContestPrizesList");
                this.$emit("close");
              }
            })
            .catch((e) => {
              EventBus.$emit("changeLoadingState", false);
              console.log(e);
              this.$alert(e.message);
            })
      } catch (e) {
        EventBus.$emit("changeLoadingState", false);
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadItem() {
      try {
        if (!this.marketingPromoContestPrize.ItemId) {
          return;
        }
        this.$apollo.query({
          query: Items.Queries.Item,
          variables: {
            id: this.marketingPromoContestPrize.ItemId,
          }
        })
            .then(({data}) => {
              if (!data || !data.item) {
                return this.$alert("Der Artikel konnte nicht geladen werden.");
              }
              this.item = data.item;
            })
            .catch((e) => {
              console.log(e);
              this.$alert(e.message);
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  },
}
</script>

<style scoped>
</style>
